@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600&display=swap');

:root {
    --content-max-width: 1240px;
    
    --gutter-1: 8px;
    --gutter-2: 16px;
    --gutter-3: 24px;
    --gutter-4: 32px;
    --gutter-5: 40px;
    --gutter-6: 48px;
    --gutter-7: 56px;
    --gutter-8: 64px;
    --gutter-9: 72px;
    --gutter-10: 80px;

    --border-radius: 20px;
    --height-header: 60px;

    --font: 'Work Sans', sans-serif;
    --font-size: 14px;

    --height-input-small: 30px;
    --height-input-medium: 50px;
    --height-input-large: 70px;

    --state-transition: 200ms ease-in-out;

    --dark-theme-background: #000;
    --light-theme-background: #fff;
}

@media (max-width: 1240px) {
    :root {
        --content-max-width: 992px;
    }
}

@media (max-width: 992px) {
    :root {
        --content-max-width: 768px;
    }
}

[data-theme="dark"] {
    --color-primary: #d16c00;
    --color-primary-75: #d16c0075;
    --color-primary-50: #d16c0050;
    --color-primary-25: #d16c0025;

    --color-secondary: #333333;
    --color-secondary-75: #33333375;
    --color-secondary-50: #33333350;
    --color-secondary-25: #33333325;

    --color-success: #00d100;

    --color-background: var(--dark-theme-background);
    --color-background-hover: rgba(255, 255, 255, 0.1);
    --color-card-background: #131315;
    --color-card-skeleton-shimmer: #434343;
    --color-text: #fff;
    --color-text-alt: #8a8a93;
    --color-text-inactive: #8a8a93;
    --color-background-input: #1c1c1f;
    --color-background-input-focus: #26262a;
}

[data-theme="light"] {
    --color-primary: #0065d1;
    --color-primary-75: #0065d175;
    --color-primary-50: #0065d150;
    --color-primary-25: #0065d125;

    --color-secondary: #c1c1c1;
    --color-secondary-75: #c1c1c175;
    --color-secondary-50: #c1c1c150;
    --color-secondary-25: #c1c1c125;

    --color-success: #008400;

    --color-background: var(--light-theme-background);
    --color-background-hover: rgba(0, 0, 0, 0.1);
    --color-card-background: #eeeeee; 
    --color-card-skeleton-shimmer: #cdcdcd;
    --color-text: #000;
    --color-text-alt: #6e6e6e;
    --color-text-inactive: #a0a0aa;
    --color-background-input: #E1E1E1;
    --color-background-input-focus: #D4D4D4;
}