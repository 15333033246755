* {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    font-family: var(--font);
    font-size: var(--font-size);
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
    margin: 0;
}

h1, .h1 {
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: -3px;
    line-height: 1;

    @media (min-width: 768px) {
        font-size: 4.2rem;
    }
}

h2, .h2 {
    font-size: 2.4rem;
    font-weight: 500;
    letter-spacing: -3px;
    line-height: 1;

    @media (min-width: 768px) {
        font-size: 3.2rem;
    }
}

h3, .h3 {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1.1;

    @media (min-width: 768px) {
        font-size: 3rem;
    }
}

h4, .h4 {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1.2; 

    @media (min-width: 768px) {
        font-size: 2.2rem;
    }
}

h5, .h5 {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 1.2;

    @media (min-width: 768px) {
        font-size: 1.8rem;
    }
}

.text {
    font-size: 1.5rem;
    line-height: 1.7;
    letter-spacing: 1px;;
    margin: 0;
}

body.theme-dark {
    background-color: var(--dark-theme-background);
}

body.theme-light {
    background-color: var(--light-theme-background);
}

/* width */
*::-webkit-scrollbar {
    width: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: var(--color-secondary);
    width: 8px;
    border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}